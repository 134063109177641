<template>
  <div class="hello">
    <h1>Simple Video Recording using RecordRTC</h1>
    <br>
    <v-btn i :disabled="disabled" @click="startRec">Start Recording</v-btn>&nbsp;&nbsp;
    <v-btn
     
      :disabled="!disabled"
      @click="stopRec"
    >Stop Recording</v-btn>&nbsp;&nbsp;
    <v-btn :disabled="!downloadReady" @click="downloadRec">Download Recording</v-btn>
    
    <video v-if="isVideoVisible"  autoplay playsinline muted ref="video"  width="200"> </video>
  </div>
</template>

<script>
import RecordRTC from "recordrtc";
export default {
  name: "VideoRecord",
  data() {
    return {
      recorder: null,
      disabled: false,
      downloadReady: false,
      recordedBlob: null,
      isVideoVisible: false
    };
  },
  methods: {
    startRec() {
      this.disabled = true;
      this.downloadReady = false;
      this.isVideoVisible = true;
      this.captureCamera(camera => {
        const video = this.$refs.video;
        video.muted = true;
        video.volume = 0;
        video.srcObject = camera;
        this.recorder = RecordRTC(camera, {
          type: "video"
        });
        this.recorder.startRecording();
        // release camera on stopRecording
        this.recorder.camera = camera;
        this.disabled = true;
      });
    },
    stopRec() {
      this.disabled = false;
      this.recorder.stopRecording(this.stopRecordingCallback);
    },
    captureCamera(callback) {
      navigator.mediaDevices
        .getUserMedia({ audio: true, video: true })
        .then(function(camera) {
          callback(camera);
        })
        .catch(function(error) {
          alert("Unable to capture your camera. Please check console logs.");
          console.error(error);
        });
    },
    stopRecordingCallback() {
      const video = this.$refs.video;
      video.src = video.srcObject = null;
      video.muted = false;
      video.volume = 1;
      this.recordedBlob = this.recorder.getBlob();
      video.src = URL.createObjectURL(this.recordedBlob);
      this.downloadReady = true;
      this.recorder.camera.stop();
      this.recorder.destroy();
      this.recorder = null;
      this.isVideoVisible = false; // ซ่อนวีดีโอเมื่อหยุดการบันทึก
    },
    downloadRec() {
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(this.recordedBlob);
      downloadLink.download = 'recording.mp4';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  }
};
</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
